<template>
  <div>
    <!-- 导航 -->
    <el-breadcrumb>
      <el-breadcrumb-item>
        <router-link to="./">首页</router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item>留学案例</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 主体内容 -->
    <div class="content">
      <el-form
        inline
        label-position="right"
        label-width="60px"
        size="small"
        class="query-form"
      >
        <el-form-item label="搜索">
          <el-input v-model="query.key" placeholder="请输入关键词"></el-input>
        </el-form-item>
   <!-- 学校顶部  学校下拉选择分类 -->
        <el-form-item label="学校">
          <el-select
            v-model="query.schID"
            placeholder="请选择学校"
            clearable
            filterable
            @change="selList()"
          >
            <el-option
              v-for="item in schlist"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 学校底部 -->
    <!-- 查询按钮顶部 点击事件定义 事件名字selList -->
        <el-form-item>
          <el-button type="primary" @click="selList()">查询</el-button>
          <!-- 查询 -->
          <el-button
            type="success"
            @click="
              dialogVisible = true;
              form = { isEnable: true };
              selectedOptions=[];
            "
            >新增</el-button
          >
        </el-form-item>


      </el-form>
      <!-- 列表查询 -->
      <el-card class="box-card table">
        <div slot="header" class="clearfix">
          <span>留学案例</span>
        </div>
        <!-- 列表定义 -->
        <el-table
          v-loading="loading"
          :data="tableData"
          ref="tableData"
          border
          stripe
          style="width: 100%"
        >
          <el-table-column prop="realName" label="姓名"></el-table-column>
          <!-- <el-table-column label="代表图">
            <template slot-scope="scope" width="150px">
              <img :src="domain + scope.row.imgUrl" :style="{ height: '50px' }" alt />
            </template>
          </el-table-column> -->
          <el-table-column prop="eduBack" label="学历"></el-table-column>
          <el-table-column prop="schName" label="录取学校"></el-table-column>
          <el-table-column prop="special" label="录取专业"></el-table-column>
          <el-table-column prop="collage" label="大学"></el-table-column>
          <el-table-column prop="major" label="专业"></el-table-column>
          <el-table-column prop="signTime" label="签约时间"></el-table-column>
          <el-table-column prop="sort" label="排序"></el-table-column>
          <el-table-column prop="viewCount" label="浏览次数"></el-table-column>
          <!-- 2 排序增加 -->
          <!-- <el-table-column prop="prop"  label="label"></el-table-column> -->
          <el-table-column prop="createTime" label="添加时间"></el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button-group>
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    size="mini"
                    icon="el-icon-edit"
                    @click="editClick(scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="warning"
                    size="mini"
                    icon="el-icon-delete"
                    @click="delClick(scope.row)"
                  ></el-button>
                </el-tooltip>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页条 -->
        <div class="block" style="margin-top: 20px">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[10, 20, 30, 50, 100]"
            :page-size="pageSize"
            layout="total, sizes,prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </el-card>
    </div>

    <!-- dialog 操作对话框 -->
    <el-dialog
      :title="form.id > 0 ? '编辑留学案例' : '添加留学案例'"
      :visible.sync="dialogVisible"
      @opened="dialogOpened()"
    >
      <el-form
        :model="form"
        ref="form"
        label-width="100px"
        :status-icon="true"
        @submit.native.prevent="submitForm('form')"
      >
        <el-tabs type="card">
          <el-tab-pane label="基本信息">
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item
                  label="姓名"
                  prop="realName"
                  :rules="[{ required: true, message: '不能为空' }]"
                >
                  <el-input
                    v-model="form.realName"
                    placeholder="请输入姓名"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="籍贯"
                  prop="province"
                  :rules="[{ required: true, message: '不能为空' }]"
                >
                  <el-select
                    v-model="form.province"
                    placheolder="请选择省份"
                    clearable
                    filterable
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in arealist"
                      :key="item.id"
                      :value="item.areaName"
                      :label="item.areaName"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="代表图">
              <div id="uploadBtn1" class="uploader uploader-warning"></div>
              <img
                id="relativeName"
                :src="domain + form.imgUrl"
                style="height: 40px; position: relative; left: 10px; top: 20px"
                v-if="form.imgUrl"
              />
              <div class="uploader-text">* 代表图尺寸：推荐330px*330px</div>
            </el-form-item>
<!-- 外键  学校关联 -->
            <el-form-item
              label="录取学校"
              prop="schID"
              :rules="[{ required: true, message: '不能为空' }]"
            >
              <el-select
                v-model="form.schID"
                placheolder="请选择录取学校"
                clearable
                filterable
                style="width: 100%"
              >
                <el-option
                  v-for="item in schlist"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </el-form-item>

     <!-- 学历 -->
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item
                  label="学历"
                  prop="eduBack"
                  :rules="[{ required: true, message: '不能为空' }]"
                >
                <el-select v-model="form.eduBack" placeholder="请选择学历" clearable>
                  <el-option
                  v-for="item in edulist"
                  :key="item.id"
                  :value="item.name"
                  :label="item.name"
                  ></el-option>
                </el-select>
                  <!-- <el-input
                    v-model="form.eduBack"
                    placeholder="请输入学历"
                  ></el-input> -->
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="录取专业" prop="special">
                  <el-input
                    v-model="form.special"
                    placeholder="请输入录取专业"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <!-- 大学 -->
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item
                  label="大学"
                  prop="collID"
                  :rules="[{ required: true, message: '不能为空' }]"
                >
                <el-select v-model="form.collID" placeholder="请选择大学" clearable filterable style="width:100%;">
                  <el-option
                  v-for="item in collist"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                  ></el-option>
                </el-select>
                  <!-- <el-input
                    v-model="form.eduBack"
                    placeholder="请输入学历"
                  ></el-input> -->
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="专业" prop="majorID">
                <el-select v-model="form.majorID" placeholder="请选择大学专业" clearable filterable style="width:100%;">
                  <el-option
                  v-for="item in majorlist"
                  :key="item.id"
                  :value="item.title"
                  :label="item.title"
                  ></el-option>
                </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="留学时间">
              <el-cascader
                :options="timelist"
                v-model="selectedOptions"
                @change="abroadTimeChange"
                clearable
                :props="{value:'id',label:'value'}"
                    placeholder="选择留学时间"
                    style="width: 100%"
              ></el-cascader>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="signTime" label="签约时间">
                  <el-date-picker
                    v-model="form.signTime"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择签约时间"
                    style="width: 100%"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>

            <el-form-item label="标签" prop="labelList">
              <el-input
                v-model="form.labelList"
                placeholder="请输入标签,逗号隔开"
              ></el-input>
            </el-form-item>
          </el-tab-pane>
 <!-- 1 排序字段顶部 --> 
    <el-form-item label="排序字段" prop="sort">
        <el-input
         v-model.number="form.sort"
         placeholder="请输入排序字段"
        
        
        ></el-input>
    </el-form-item>
<!-- 排序底部 -->


          <el-tab-pane label="详细内容">
            <el-form-item label="内容" prop="content">
              <vue-ueditor-wrap
                v-model="form.content"
                :config="editorConfig"
              ></vue-ueditor-wrap>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>

        <el-form-item>
          <el-button type="primary" :loading="submiting" native-type="submit"
            >提交</el-button
          >
          <el-button @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import url from "@/plugins/urlHelper.js";
import VueUeditorWrap from "vue-ueditor-wrap";
export default {
  components: { VueUeditorWrap },
  data() {
    return {
      editorConfig: {
        initialFrameHeight: 500, //设置高度
        initialFrameWidth: "100%", //设置宽度
        UEDITOR_HOME_URL: url.getUEConfig(),
        serverUrl: url.getUE(),
      },
      domain: url.getDomain(),
      query: {}, //对象
      loading: false,
      currentPage4: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      form: {}, //表单对象
      dialogVisible: false,
      submiting: false,
      id: 0,
      arealist: [
        { value: 1, label: "北京", children: [{ value: 2, label: "北京市" }] },
      ], //地区列表
      schlist: [], //录取学校
      timelist:[],//留学时间
      selectedOptions: [],
      edulist:[],//学历列表
      collist:[],//大学
      majorlist:[],//专业
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage4 = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage4 = val;
      this.getList();
    },
    //筛选
    filterTag(filter) {
      var val = filter["isEnable"][0];
      this.form.isEnable = val;
      //刷新列表
      this.getList();
    },
    //排序
    sortChange(sortColumn) {
      this.form.sort = sortColumn.prop;
      this.form.order = sortColumn.order;
      //刷新列表
      this.getList();
    },
    //获取留学时间--月份
    getMonth: function () {
        return [
            {id:1,value:1},
            {id:4,value:4},
            {id:7,value:7},
            {id:10,value:10},
        ]
    },
    //留学时间
    getTimeList() {
      var yearList = [];
      var year = new Date().getFullYear();
      var monthList = this.getMonth();
      for (var i = year; i > year - 10; i--) {
        yearList.push({
          id: i,
          value: i,
          children: monthList
        });
      }
      this.timelist = yearList;
    },
    //选择留学时间
    abroadTimeChange() {
      this.form.abroadYear = this.selectedOptions[0];
      this.form.abroadMonth = this.selectedOptions[1];
    },
    // 获取所有大学专业
    getAllMajor(){
      var _this=this;
      var link=url.getStuCase('GetAllMajor');
      $.get(link,{},data=>{
        _this.majorlist=data;
      })
    },
    // 获取所有语言学校
    getAllSchoolList: function () {
      var _this = this;
      var link = url.getSchool("getallschool");
      $.get(link, {}, (data) => {
        _this.schlist = data;
      });
    },
    // 获取所有的大学
    getAllCollege(){
      var _this = this;
      var link = url.getSchool("GetAllCollege");
      $.get(link, {}, (data) => {
        _this.collist = data;
      });
    },
    // 获取籍贯
    getChAreaList: function () {
      var _this = this;
      var link = url.getCommon("getarea_china");
      $.get(link, {}, (data) => {
        _this.arealist = data;
      });
    },
    // 学历
    getEduBackList(){
      var _this=this;
      var link=url.getStuCaseCollege('GetEduBack');
      $.get(link,{},data=>{
        _this.edulist=data;
      })
    },
    // 查询列表
    getList: function () {
      var _this = this;
      _this.loading = true;
      var params = _this.query;
      params.pageindex = _this.currentPage4;
      params.pagesize = _this.pageSize;
      var link = url.getStuCase("getlist_case");

      $.get(link, params, (data) => {
        _this.loading = false;
        _this.tableData = data.list;
        _this.total = data.total;
      });
    },
    //搜索查询输入框     函数定义
    selList: function () {
      this.currentPage4 = 1;
      this.getList();
    },
    //上传图片
    dialogOpened: function () {
      var _this = this;

      // 上传图片
      if ($("#uploadBtn1 input").length == 0) {
        $("#uploadBtn1").uploader({
          url: url.getUpload(),
          text: "上传图片",
          fileExts: "jpg;png",
          type: "dialog",
          coverParams: {
            targetWidth: 330,
            targetHeight: 330,
            // itemWidth: 700,
            // itemheight:500,
          },
          maxSize: 1024 * 1024 * 1,
          onSuccess: function (data) {
            var src = url.getDomain() + data.relativeName;
            _this.$set(_this.form, "imgUrl", data.relativeName);
          },
        });

        // $("#uploadBtn1").uploader({
        //   url: url.getUpload(),
        //   text: "上传图片",
        //   fileExts: "jpg;png",
        //   handleType: "0",
        //   maxSize: 1024 * 1024 * 1,
        //   onSuccess: function (data) {
        //     var src = url.getDomain() + data.relativeName;
        //     _this.$set(_this.form, "imgUrl", data.relativeName);
        //   },
        // });
      }
    },
    //提交表单
    submitForm(formName) {
      var _this = this;
      var link = url.getStuCase("save_case");
      var info = this.form;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submiting = true;
          //提交后台添加
          //添加或者修改
          $.post(link, info, (res) => {
            _this.submiting = false;
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    //编辑
    editClick: function (row) {
      //   this.cateList = [row.cateID1, row.cateID2];
      //   this.form = Object.assign({}, row);
      //   this.dialogVisible = true;
      var _this = this;
      var id = row.id;
      var link = url.getStuCase("getmodel_case");
      $.get(link, { id: id }, (res) => {
        _this.form = res;
        _this.selectedOptions = [res.abroadYear, res.abroadMonth];
        _this.dialogVisible = true;
      });
    },
    //删除
    delClick: function (row) {
      var _this = this;
      var id = row.id;
      var link = url.getStuCase("delete_case"); //获取请求地址

      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        concelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          $.post(link, { id: id }, (res) => {
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  mounted() {
    // 获取所有大学专业
    this.getAllMajor();
    // 获取所有大学
    this.getAllCollege();
    // 获取学历
    this.getEduBackList();
    // 获取所有的大学
    this.getAllSchoolList();
    // 获取籍贯
    this.getChAreaList();
    // 留学时间
    // 学校时间
    this.getTimeList();
    //获取列表
    this.getList();
  },
};
</script>

<style>
.query-form {
  margin-top: 20px;
  padding-top: 25px;
  background: #f2f2f2;
}
.el-form-item--small.el-form-item {
  margin-right: 0px;
}
.el-button--primary {
  margin-left: 10px;
}
</style>
